import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { api_url } from '../Config';
import swal from 'sweetalert';
import Footer from './Footer';
import CommonHeader from '../component/CommonHeader';
import { english } from '../component/English';
import { french } from '../component/French';
import lang_icon from '../assets/images/lang-black-icon.png';
class Home extends Component {
  constructor() {
    super();
    this.state = {
      redirectToReferrer: false,
      base_url: api_url.base_url,
      email: '',
      password: '',
      loading: false,
      allLang: [],
      english: english,
      french: french,
    };
    let lang_name = localStorage.getItem('lang_name');
    if (lang_name == null) {
      localStorage.setItem('lang', JSON.stringify(this.state.english));
      localStorage.setItem('lang_name', 'english');
    }
    this.onChange = this.onChange.bind(this);
    this.login = this.login.bind(this);
    this.getLange = this.getLange.bind(this);
  }
  componentDidMount() {


  }
  getLange(e) {
    let language = e.target.value;
    let lang = this.state.english;
    if (language == 'english') {
      lang = this.state.english;
    } else {
      lang = this.state.french;
    }
    localStorage.setItem('lang', JSON.stringify(lang));
    localStorage.setItem('lang_name', language);



  }
  componentWillMount() {
    console.log(this.state)
    if (localStorage.getItem("customer_id")) {
      this.setState({ redirectToReferrer: true });
    }
    else {
      this.getUserData();
    }
  }
  getUserData() {
    JSON.parse(localStorage.getItem("customer_id"));

  }

  login() {
    if (this.state.email === '' || this.state.password === '') {
      alert('Email and Password required.')
    } else {
      this.setState({ loading: true })
      let signupData = {
        email: this.state.email,
        'password': this.state.password
      }
      const addUrl = this.state.base_url + 'customer_api.php?type=customer_login';
      fetch(addUrl,
        {

          method: 'POST',
          headers:
          {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(signupData)
        })
        .then((response) => response.json()
          .then((result) => {
            if (result.customer_id > 0) {

              localStorage.setItem('customer_id', result.customer_id);
              this.setState({ redirectToReferrer: true, loading: false }, () => {
                window.location.reload();
              });

            } else {
              swal(result.message);
              this.setState({ loading: false })
            }
          }))
        .catch((error) => {
          this.setState({ loading: false })
        });
    }


  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    let lang_name = localStorage.getItem('lang_name');
    if (lang_name == '') {
      localStorage.setItem('lang', JSON.stringify(this.state.english));
      localStorage.setItem('lang_name', 'english');
    }
    const lang = JSON.parse(localStorage.getItem("lang"));
    console.log(localStorage.getItem("lang_name"))
    if (this.state.redirectToReferrer) {
      return (<Redirect to='dashboard' />)
    }
    return (

      <React.Fragment>
        <div id="page-name">
          <CommonHeader />
          <div className="main_body body_img_bg">
            <div className="login_screen fix_screen" id="register_screen" style={{ padding: '63px 0 0 !important' }}>

              <div className="row">
                <div className="col-lg-12 fix_logo_f register_info">
                  <h3>{lang.lets_start}</h3>
                  <p>{lang.plz_enter_requested_info}</p>
                </div>
              </div>
              <div className="track_shipments" id="login_desktop">
                <div className="form_box get_quote">
                  <input type="email" placeholder="Email Adress " required="required" onChange={this.onChange} name="email" />
                  <svg style={{ top: '7px' }} className="search_box" viewBox="0 0 24 24"><path d="M21 9v9a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V9c0-1.11.603-2.08 1.5-2.598l-.003-.004l8.001-4.62l8.007 4.623l-.001.003A2.999 2.999 0 0 1 21 9zM3.717 7.466L11.5 12.52l7.783-5.054l-7.785-4.533l-7.781 4.533zm7.783 6.246L3.134 8.28A1.995 1.995 0 0 0 3 9v9a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2V9c0-.254-.047-.497-.134-.72L11.5 13.711z" fill="#626262" /></svg>
                </div>
                <div className="form_box get_quote">
                  <input type="password" placeholder="Password" onChange={this.onChange} name="password" />

                  <svg ><path fillOpacity=".886" d="M16 8a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-8a3 3 0 0 1 3-3V6.5a4.5 4.5 0 1 1 9 0V8zM7 9a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2H7zm8-1V6.5a3.5 3.5 0 0 0-7 0V8h7zm-3.5 6a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm0-1a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5z" fill="#626262" /></svg>
                </div>
                <div className="form_box lang_select get_quote">
                  <select onChange={this.getLange}>
                    <option selected={localStorage.getItem('lang_name') == 'english' ? true : false} value="english">English</option>
                    <option selected={localStorage.getItem('lang_name') == 'french' ? true : false} value="french">French</option>
                  </select>
                  <img src={lang_icon} alt="" />
                </div>


                <div id="signup_box">
                  {
                    // <button onClick={()=>this.login()} type="button" className="contact_btn login_btn_ mb-0 ml-2 mt-0 mr-2 w-75">Sign In</button>
                  }

                  <button className="contact_btn login_btn_ mb-0 ml-2 mt-0 mr-2 w-75" type="button" onClick={() => this.login()} disabled={this.state.loading}>
                    {this.state.loading && (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    {this.state.loading && <span>{lang.sign_in}</span>}
                    {!this.state.loading && <span>{lang.sign_in}</span>}
                  </button>
                </div>

                <div>
                  {lang.dont_have_accunt} <Link to='/customer-signup'>{lang.sign_up}</Link>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    )
  }
}
export default Home;
